import React from 'react';

function Intro() {
  return (
    <section className="intro section-padding">
      <div className="container">
        <div className="row lg-marg">
          <div className="col-lg-8 md-mb80">
            <div className="row lg-marg align-items-center">
              <div className="col-md-6">
                <div className="img1 sm-mb50 d-none d-sm-block">
                  <img src="/assets/imgs/pm/p_shape.png" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="text">
                  <h3 className="mb-30">
                  Unlock your full potential{' '}
                    <span className="fw-300">as a</span>{' '}
                    <span className="fw-300">model.</span>
                  </h3>
                  <p>
                    We empower models like you to grow your brand, maximize earnings, and focus on what you do best—creating content.
                  </p>
                  <br></br>
                  <p>
                  Interested in becoming a model, and joining our team? Profile Mgmt works locally, nationally, and internationally.                  
                  </p>

                  {/* <a href="/page-about" className=" main-color mt-40">
                    <span className="text">
                      Follow on Instagram 
                    </span>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="numbers mt-80">
              <div className="row lg-marg">
                <div className="col-md-6">
                  <div className="item bord-thin-top pt-30 d-flex align-items-end mt-20">
                    <div>
                      <h3 className="fw-300 mb-10">250+</h3>
                      <h6 className="p-color sub-title">
                        Models Managed
                      </h6>
                    </div>
                    <div className="ml-auto">
                      <div className="icon-img-40">
                        <img src="/assets/imgs/pm/p_shape_outline.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="item bord-thin-top pt-30 d-flex align-items-end mt-20">
                    <div>
                      <h3 className="fw-300 mb-10">49%</h3>
                      <h6 className="p-color sub-title">Avg Increase In Sales</h6>
                    </div>
                    <div className="ml-auto">
                      <div className="icon-img-40">
                        <img src="/assets/imgs/pm/p_shape_outline.png" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="img-full fit-img">
              <img src="/assets/imgs/models/girl1.jpg" alt="" style={{borderRadius: 10}} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Intro;
