'use client';
import loadBackgroudImages from '../../common/loadBackgroudImages';
import React, { useEffect } from 'react';

function Feat() {
  useEffect(() => {
    loadBackgroudImages();
  }, []);
  return (
    <section className="feat section-padding">
      <div className="container ontop">
        <div className="row">
          <div className="col-lg-5">
            <div className="cont md-mb50">
              <h6 className="sub-title main-color mb-15">Trusted Services</h6>
              <h3 className="mb-30">
                Expert Management Tailored to Boost Your Success
              </h3>
              <p>
               We provide discreet, top-tier management services that allow you to focus on creating while we handle the rest. We ensure your online presence is not only maintained but thrives.
              </p>
              <a
                href="https://www.instagram.com/profilemgmt/" target='_blank'
                className="butn-crev d-flex align-items-center mt-40"
              >
                <span className="hover-this">
                  <span className="circle hover-anim">
                    <i className="ti-arrow-top-right"></i>
                  </span>
                </span>
                <span className="text">Follow on Instagram</span>
              </a>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="item mb-30">
              <div className="row">
                <div
                  className="col-md-4 bg-img"
                  data-background="/assets/imgs/pm/monitoring.png"
                ></div>
                <div className="col-md-8">
                  <div className="info">
                    <h5 className="mb-15">Account Monitoring</h5>
                    <p>
                      We monitor your modeling accounts daily, ensuring that your profile stays active and engaging, responding promptly to any issues that arise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item mb-30">
              <div className="row">
                <div
                  className="col-md-4 bg-img"
                  data-background="/assets/imgs/pm/content.png"
                ></div>
                <div className="col-md-8">
                  <div className="info">
                    <h5 className="mb-15">Content Management</h5>
                    <p>
                      From scheduling posts to optimizing content, we handle all aspects of content management, ensuring your feed is always fresh and engaging.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="item mb-30">
              <div className="row">
                <div
                  className="col-md-4 bg-img"
                  data-background="/assets/imgs/pm/chatting.png"
                ></div>
                <div className="col-md-8">
                  <div className="info">
                    <h5 className="mb-15">Exclusive Events</h5>
                    <p>
                      Join our exclusive networking events to connect with industry leaders, expand your professional network, and discover new opportunities to grow your career. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-blur">
        <img src="/assets/imgs/patterns/blur1.png" alt="" />
      </div>
    </section>
  );
}

export default Feat;
