import React from 'react';

function Marq() {
  const marquee = [
    'PROFILE MGMT',
    'SILENT MANAGEMENT',
  ];
  return (
    <section className="opacity-7">
      {/* <div className="main-marq xlrg o-hidden">
        <div className="slide-har st1 strok">
          <div className="box">
            {marquee.map((item, i) => (
              <div key={i} className="item">
                <h4 className="d-flex align-items-center">
                  <span>{item}</span>
                  <span className="icon-img-20 ml-40">
                    <img src="/assets/imgs/pm/p_shape_outline.png" alt="" />
                  </span>
                </h4>
              </div>
            ))}
          </div>
          <div className="box">
            {marquee.map((item, i) => (
              <div key={i} className="item">
                <h4 className="d-flex align-items-center">
                  <span>{item}</span>
                  <span className="icon-img-20 ml-40">
                    <img src="/assets/imgs/pm/p_shape_outline.png" alt="" />
                  </span>
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="main-marq xlrg o-hidden">
        <div className="slide-har st2 non-strok">
          <div className="box">
            {marquee.map((item, i) => (
              <div key={i} className="item">
                <h4 className="d-flex align-items-center">
                  <span>{item}</span>
                  <span className="icon-img-20 ml-40">
                    <img src="/assets/imgs/pm/p_shape_outline.png" alt="" />
                  </span>
                </h4>
              </div>
            ))}
          </div>
          <div className="box">
            {marquee.map((item, i) => (
              <div key={i} className="item">
                <h4 className="d-flex align-items-center">
                  <span>{item}</span>
                  <span className="icon-img-20 ml-40">
                    <img src="/assets/imgs/pm/p_shape_outline.png" alt="" />
                  </span>
                </h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Marq;
